<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="年月"
                                    v-model="year_month_select"
                                    :disabled="edit"
                                    @getValue="val=>{this.ym=val;getData()}"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-select label="分店"
                                    v-model="storeList"
                                    @getValue="val=>{this.id_store=val;}"></mdb-select>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2"
                  v-for="(g,gk) in group"
                  :key="g.title">
            <mdb-card-header color="default">
                <span style="margin:-0.75em 0;cursor: pointer;"
                      @click="g.show=!g.show">
                    <i class="fas fa-chevron-circle-up"
                       :class="g.show ?'fa-rotate-180':''"></i>
                    &nbsp;{{g.title}}</span>
            </mdb-card-header>
            <mdb-card-body v-show="g.show ">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th style="width:3rem">#</th>
                            <!-- <th>分店</th> -->
                            <th>項目名稱</th>
                            <th style="width:10rem">金額</th>
                            <th style="width:15rem">備註</th>
                            <th style="width:5rem"
                                v-show="canedit"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(d,dk) in data_filter[gk]"
                            :key="d.id">
                            <td>{{dk+1}}</td>
                            <!-- <td>
                                <select class="form-control"
                                        v-model="d.id_store">
                                    <option v-for="s in storeList"
                                            :key="s.id"
                                            :value="s.id">{{s.name}}</option>
                                </select>
                            </td> -->
                            <td>
                                <mdb-input class="p-0 m-0"
                                           v-model="d.name"
                                           v-show="canedit"
                                           @change="edit=true"></mdb-input>
                                {{!canedit?d.name:''}}
                            </td>
                            <td>
                                <mdb-input type="number"
                                           class="p-0 m-0 text-right"
                                           v-model.number="d.price"
                                           v-show="canedit"
                                           @change="edit=true"></mdb-input>
                                {{!canedit?d.price:''}}
                            </td>
                            <td>
                                <mdb-input type="textarea"
                                           class="p-0 m-0"
                                           :rows="1"
                                           v-model="d.remark"
                                           v-show="canedit"
                                           @change="edit=true"></mdb-input>
                                <span v-show="!canedit"
                                      v-html="$nl2br(d.remark)"></span>
                            </td>
                            <td v-show="canedit">
                                <mdb-btn color='danger'
                                         size="sm"
                                         @click="del_item(d.id)">
                                    <i class="fa fa-trash"></i>
                                </mdb-btn>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <mdb-btn size="sm"
                         @click="addsalary()"
                         v-show="gk==0">
                    <i class="fa fa-plus"></i>
                    &nbsp;新增人員
                </mdb-btn>
                <mdb-btn size="sm"
                         @click="add_item({type:gk})"
                         v-show="gk>0">
                    <i class="fa fa-plus"></i>
                    &nbsp;新增項目
                </mdb-btn>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right">
            <mdb-btn @click="save_data()">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbInput,
  mdbSelect,
} from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbInput,
    mdbSelect,
  },
  data() {
    return {
      ym: "",
      add_count: 0,
      year_month_select: [],
      storeList: [],
      dataList: [],
      dataEmpty: {},
      del_ids: [],
      id_store: "",
      group: [
        { title: "人事成本", show: true },
        { title: "營業成本", show: true },
        { title: "採購項目", show: true },
      ],
      edit: false,
    };
  },
  mounted() {
    let vue = this,
      ylimit = vue.$moment().format("YYYY"),
      ymlimit = vue.$moment().format("YYYY-MM");
    vue.ym = vue.$moment().format("YYYY-MM");
    for (let y = ylimit; y >= 2020; y--) {
      vue.year_month_select.push({
        text: y + "年",
        value: null,
        disabled: true,
        optgroup: true,
      });
      for (let m = 12; m > 0; m--) {
        let str = `${y}-${m < 10 ? "0" : ""}${m}`;
        if (ymlimit < str) continue;
        vue.year_month_select.push({
          text: `${y}年${m < 10 ? "0" : ""}${m}月`,
          value: str,
          selected: vue.ym == str,
        });
      }
    }
    vue.getData();
  },
  computed: {
    data_filter() {
      let vue = this,
        output = { 0: [], 1: [], 2: [] };
      vue.dataList.forEach((item) => {
        if (item.id_store == vue.id_store) {
          output[item.type].push(item);
        }
      });
      return output;
    },
    canedit() {
      return true;
    },
  },
  methods: {
    addsalary(name) {
      let vue = this;
      if (!name) {
        vue.$swal.fire({
          title: "請輸入員工姓名",
          input: "text",
          showCancelButton: true,
          preConfirm(val) {
            if (val != "") {
              vue.addsalary(val);
            } else {
              vue.$swal.showValidationMessage("請輸入姓名");
            }
          },
        });
      } else {
        vue.add_item({
          type: 0,
          id_store: vue.id_store,
          name: name + "-薪資",
        });
        vue.add_item({
          type: 0,
          id_store: vue.id_store,
          name: name + "-勞工退休金（6%）",
        });
        vue.add_item({
          type: 0,
          id_store: vue.id_store,
          name: name + "-勞工保險",
        });
        vue.add_item({
          type: 0,
          id_store: vue.id_store,
          name: name + "-健保",
        });
      }
    },
    add_item(obj) {
      let vue = this;
      obj = obj || {};
      vue.dataList.push(
        Object.assign({}, vue.dataEmpty, { id_store: vue.id_store }, obj, {
          id: `add_${vue.add_count++}`,
          ym: vue.ym,
        })
      );
    },
    del_item(del_id) {
      let vue = this,
        index = vue.dataList.findIndex((item) => {
          return item.id == del_id;
        });
      if (del_id.indexOf("add") == -1) {
        vue.del_ids.push(del_id);
      }
      vue.dataList.splice(index, 1);
    },
    getData() {
      let vue = this,
        query = [
          {
            name: "operating_costs",
            data: {
              ym: {
                type: 0,
                value: vue.ym,
              },
            },
            sort: {
              type: 0,
            },
          },
          {
            name: "operating_costs",
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: 1,
              },
            },
            sort: {
              name: 0,
            },
          },
        ];
      vue.$swal.fire({
        title: "處理中",
        html: '<i class="fa fa-4x fa-sync fa-spin"></i>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen() {
          vue.$store
            .dispatch("get_form", {
              payload: {
                url: `data/get_data/?query=${JSON.stringify(query)}`,
              },
            })
            .then((res) => {
              vue.storeList = res.data.storeList.map((item, index) => {
                item.text = item.name;
                item.value = item.id;
                item.selected = index == 0;
                return item;
              });
              vue.dataEmpty = res.data.operating_costsEmpty;
              vue.dataEmpty.price = 0;
              // 沒有資料的話就先設定預設值
              if (res.data.operating_costsList.length == 0) {
                vue.dataList = [];
                res.data.storeList.forEach((s) => {
                  // 固定營業費用項目
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "年終獎金",
                    remark: "年度",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "發票平臺年費",
                    remark: "年度",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "保險",
                    remark: "人險、火險",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "負責人税",
                    remark: "年度",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "會計費",
                    remark: "三個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "水費",
                    remark: "兩個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "電費",
                    remark: "兩個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "瓦斯費",
                    remark: "兩個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "營業稅",
                    remark: "兩個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "銀行手續費（刷卡）",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "負責人勞健保",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "店面租金",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "年終獎金提撥",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "中華電信",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "開會飲食",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "土地公",
                    remark: "每個月",
                  });

                  vue.add_item({ type: 1, id_store: s.id, name: "太古" });
                  vue.add_item({ type: 1, id_store: s.id, name: "飲用桶水" });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "停車費",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "運費",
                    remark: "每個月",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "行銷費用",
                    remark: "FB 廣告",
                  });
                  vue.add_item({
                    type: 1,
                    id_store: s.id,
                    name: "諮詢指導費",
                    remark: "二月份",
                  });
                  vue.add_item({ type: 1, id_store: s.id, name: "文具" });
                  vue.add_item({ type: 1, id_store: s.id, name: "冷氣清潔" });
                  vue.add_item({ type: 1, id_store: s.id, name: "垃圾袋" });
                  vue.add_item({ type: 1, id_store: s.id, name: "補玻璃" });
                  vue.add_item({ type: 1, id_store: s.id, name: "工具" });
                  vue.add_item({ type: 1, id_store: s.id, name: "酒精" });
                });
              } else {
                vue.dataList = res.data.operating_costsList;
              }
              vue.$swal.close();
            });
        },
      });
    },
    save_data() {
      let vue = this;
      vue.$swal.fire({
        title: "儲存中",
        html: '<i class="fa fa-4x fa-sync fa-spin"></i>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen() {
          vue.$store
            .dispatch("post_form", {
              payload: {
                url: "operating_costs/save_data/",
                data: {
                  data: JSON.stringify(vue.dataList),
                  del_ids: vue.del_ids.join(","),
                },
              },
            })
            .then((res) => {
              vue.dataList.map((item) => {
                if (item.id.substring(0, 4) == "add_") {
                  item.id = res.data.new_ids[item.id];
                }
                return item;
              });
              vue.edit = false;
              vue.$swal.close();
            });
        },
      });
    },
  },
};
</script>
